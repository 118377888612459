.section-hero {
  background-image: url("hero-background.e817ab77.webp");
  background-size: cover;
  min-height: 100vh;
  display: flex;
}

.hero-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  display: flex;
  position: relative;
}

.hero-container.section-container {
  border: none;
}

.hero-heading-container {
  position: relative;
}

.hero-heading {
  font-family: var(--font-deesign--logo), serif;
  letter-spacing: -.05rem;
  color: #fff;
  font-size: 12rem;
  line-height: 1.05;
}

.hero-motto {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;
  bottom: -1rem;
  left: .7rem;
}

.hero-description {
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px #000;
  font-size: 2rem;
}

.section-major-projects .section-container {
  background-color: #8e55735b;
}

.projects-grid {
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 9.6rem;
  padding: 4.8rem;
}

.project-img-container {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: .9rem;
  place-self: center;
  width: 30rem;
  height: 30rem;
  transition: all .3s;
  overflow: hidden;
  box-shadow: 0 1.2rem 3.2rem #0003;
}

.project-img {
  object-fit: cover;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
  display: flex;
}

.project-img-container:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.project-description {
  flex-direction: column;
  grid-column: span 2;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 1.6rem;
  padding: 4.8rem;
  display: flex;
}

.other-headings-container {
  border-radius: .9rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3.3rem;
  display: flex;
}

.btn.expand-btn {
  background-color: var(--color-main);
  border: 2px solid #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  transform: translate(-.4rem, -.4rem);
}

.expand-btn:hover {
  transform: translate(0);
}

.expand-btn-shadow {
  background-color: #000;
  border-radius: 50%;
}

.expand-icon {
  width: 4.4rem;
  height: 4.4rem;
  transition: all .3s;
}

.other-headings-container {
  flex-direction: column;
  gap: 4.8rem;
}

.other-projects--grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 4.8rem;
  padding: 4.8rem 9.6rem;
  transition: all .4s, visibility 1s, opacity .1s linear;
}

.other-project {
  background-color: var(--color-background);
  border: 3px solid #000;
  border-radius: .9rem;
  transition: all .5s;
  overflow: hidden;
}

.other-project:hover {
  cursor: pointer;
  transform: translate(-1rem, -1rem);
}

.other-project-img {
  width: 100%;
  height: 100%;
}

.other-project-description {
  padding: 1.2rem;
}

.other-project-shadow {
  box-sizing: border-box;
  background-color: #000;
  border-radius: .9rem;
}

.accordion {
  flex-direction: column;
  gap: 24px;
  width: 700px;
  margin: 100px auto;
  display: flex;
}

.item {
  background-color: #fff;
  border-radius: .9rem;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 32px 24px;
  padding: 24px;
  display: grid;
  box-shadow: 0 0 32px #0000001a;
}

.accordion-number, .accordion-heading--text {
  color: #868e96;
  font-size: 24px;
  font-weight: bold;
}

.accordion-content p {
  margin-bottom: 24px;
  line-height: 1.6;
}

.accordion-content ul {
  color: #868e96;
  flex-direction: column;
  gap: 12px;
  margin-left: 20px;
  display: flex;
}

.accordion-icon {
  stroke: #868e96;
  width: 24px;
  height: 24px;
}

.accordion-content {
  grid-column: 2;
  display: none;
}

.open {
  border-top: 6px solid var(--color-main);
}

.open .accordion-content {
  display: block;
}

.open .accordion-number, .open .accordion-heading--text, .open .accordion-icon {
  color: #000;
  stroke: #000;
}

.team-grid {
  gap: 4.8rem;
}

.member-container {
  flex-direction: column;
  gap: 4.8rem;
  display: flex;
}

.member-img-container {
  border-radius: 100rem;
  overflow: hidden;
}

.member-img {
  width: 100%;
  height: 100%;
}

.member-description-container {
  border: 2px solid #000;
  border-radius: .9rem;
  padding: 1.2rem;
}
/*# sourceMappingURL=index.77ccf678.css.map */
