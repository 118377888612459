/* ///////////////////////// */
/* HERO STYLES */
/* ///////////////////////// */

.section-hero {
  background-image: url(/pages/index/img/hero-background.webp);
  background-size: cover;
  min-height: 100vh;
  display: flex;
}

.hero-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.hero-container.section-container {
  border: none;
}

.hero-heading-container {
  position: relative;
}

.hero-heading {
  font-family: var(--font-deesign--logo), serif;
  letter-spacing: -0.05rem;
  font-size: 12rem;
  line-height: 1.05;
  color: rgb(255, 255, 255);
}

.hero-motto {
  position: absolute;
  font-size: 1.5rem;
  left: 0.7rem;
  bottom: -1rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.hero-description {
  font-size: 2rem;
  text-align: center;
  color: white;
  text-shadow: 1px 1px black;
}

/* ///////////////////////// */
/* MAJOR PROJECTS SECTION */
/* ///////////////////////// */

.section-major-projects .section-container {
  background-color: #8e55735b;
}

.projects-grid {
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 9.6rem;
  padding: 4.8rem;
}
.project-img-container {
  background-color: white;
  height: 30rem;
  width: 30rem;
  border-radius: 0.9rem;
  align-self: center;
  justify-self: center;
  overflow: hidden;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.2);
  transition: 300ms;
  border: 2px solid black;
}

.project-img {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-img-container:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.project-description {
  padding: 4.8rem;
  grid-column: span 2;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

/* ///////////////////////// */
/* OTHER PROJECTS SECTION */
/* ///////////////////////// */

.other-headings-container {
  display: flex;
  align-items: center;
  border-radius: 0.9rem;
  margin-top: 3.3rem;
  width: 100%;
  justify-content: space-between;
}

.btn.expand-btn {
  border-radius: 50%;

  background-color: var(--color-main);
  border: 2px solid black;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 300ms;
  transform: translate(-0.4rem, -0.4rem);
}

.expand-btn:hover {
  transform: translate(0rem, 0rem);
}

.expand-btn-shadow {
  background-color: black;
  border-radius: 50%;
}

.expand-icon {
  width: 4.4rem;
  height: 4.4rem;
  transition: 300ms;
}

.other-headings-container {
  flex-direction: column;
  gap: 4.8rem;
}

.other-projects--grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 4.8rem;
  padding: 4.8rem 9.6rem;
  transition: all 400ms, visibility 1s, opacity 0.1s linear;
}

.other-project {
  background-color: var(--color-background);
  border-radius: 0.9rem;
  overflow: hidden;
  transition: 500ms;
  border: 3px solid black;
}

.other-project:hover {
  transform: translate(-1rem, -1rem);
  cursor: pointer;
}

.other-project-img {
  width: 100%;
  height: 100%;
}

.other-project-description {
  padding: 1.2rem;
}

.other-project-shadow {
  box-sizing: border-box;
  background-color: black;
  border-radius: 0.9rem;
}

/* ///////////////////////// */
/* SERVICES SECTION */
/* ///////////////////////// */

.accordion {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: 100px auto;
  gap: 24px;
}

.item {
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 24px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 24px;
  row-gap: 32px;
  align-items: center;
  border-radius: 0.9rem;
}
.accordion-number,
.accordion-heading--text {
  font-size: 24px;
  font-weight: bold;
  color: #868e96;
}

.accordion-content p {
  line-height: 1.6;
  margin-bottom: 24px;
}

.accordion-content ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #868e96;
  margin-left: 20px;
}

.accordion-icon {
  width: 24px;
  height: 24px;
  stroke: #868e96;
}

.accordion-content {
  grid-column: 2;
  display: none;
}

.open {
  border-top: 6px solid var(--color-main);
}

.open .accordion-content {
  display: block;
}

.open .accordion-number,
.open .accordion-heading--text,
.open .accordion-icon {
  color: black;
  stroke: black;
}

/* ///////////////////////// */
/* TEAM SECTION */
/* ///////////////////////// */

.team-grid {
  gap: 4.8rem;
}

.member-container {
  display: flex;
  gap: 4.8rem;
  flex-direction: column;
}

.member-img-container {
  border-radius: 100rem;
  overflow: hidden;
}

.member-img {
  width: 100%;
  height: 100%;
}
.member-description-container {
  padding: 1.2rem;
  border: 2px solid black;
  border-radius: 0.9rem;
}
